import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, map } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { LoginData, ResetPassword, User } from '../models/user';
import { Router } from '@angular/router';
import { OrganizationSetupService } from './organization/organization-setup.service';
import { OrganizationList } from '../models/organization/organization';
// import { JwtModule, JwtHelperService } from "@auth0/angular-jwt";
import { jwtDecode } from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HelperService } from '../helpers/helper.service';
import { ToastrService } from 'ngx-toastr';

const httpOptions = {
  headers: new HttpHeaders({
    // "mimeType": "multipart/form-data",
    // "processData": 'false',
    // "contentType": 'false'
  }),
};

interface DecodedToken {
  exp: number;
  // Add other claims as needed
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  baseUrl = environment.basicUrl;
  version = environment.version;
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();
  organizations!: OrganizationList;
  public userExp: any = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private organizationService: OrganizationSetupService,
    private _helper: HelperService,
    public router: Router,
    public toastr: ToastrService,
    private jwtHelper: JwtHelperService
  ) {
    this.userExp.next(this.isTokenExpired());
  }

  getOrganizations() {
    this.organizationService
      .getOrganizations()
      .subscribe((organizations: OrganizationList) => {
        this.organizations = organizations;
      });
  }

  login(model: any) {
    console.log(`${this.baseUrl}/${this.version}/login`);

    return this.http.post(`${this.baseUrl}/${this.version}/login`, model).pipe(
      map((response: any) => {
        const user = response;
        localStorage.setItem('user', JSON.stringify(user));
        this.currentUserSource.next(user);
        this.organizationService
          .getOrganizations()
          .subscribe((organizations: OrganizationList) => {
            this.organizations = organizations;
            if (this.organizations.data.length == 0) {
              this.router.navigate(['/setup']);
            } else {
              this.router.navigate(['/']);
            }
          });
      })
    );
  }

  setCurrentUser(user: User) {
    this.currentUserSource.next(user);
  }

  isTokenExpired() {
    const token = JSON.parse(localStorage.getItem('user')!)?.access_token;
    return this.jwtHelper.isTokenExpired(token);
  }

  // isTokenExpired(token: string): boolean {
  //   const decodedToken = jwtDecode(token) as DecodedToken;
  //   const expirationDate = decodedToken.exp * 1000; // Convert seconds to milliseconds
  //   const currentDate = new Date().getTime();

  //   console.log(decodedToken, 'decodedToken');
  //   console.log(expirationDate, 'expired date');
  //   console.log(currentDate, 'currentDate');

  //   return expirationDate < currentDate;
  // }

  Logout() {
    localStorage.removeItem('user');
    this.currentUserSource.next(null!);
  }

  resetCode(phone: any) {
    let model = {
      phone,
    };
    return this._helper.createFormData(`send_code`, model);
  }

  resetPassword(model: any) {
    return this._helper.createFormData(`reset_password`, model);
  }
}
