<div
  class="page"
  [ngStyle]="{
    'justify-content': isEnvironmentDevelopment ? 'center' : 'start'
  }"
>
  <div
    class="blue-border-box top"
    [ngStyle]="{
      'background-color': isEnvironmentDevelopment ? '#302061' : '#B6484B'
    }"
  ></div>
  <div
    class="blue-border-box bottom"
    [ngStyle]="{
      'background-color': isEnvironmentDevelopment ? '#302061' : '#E3D47B'
    }"
  ></div>
  <div class="login-as">
    <div class="header">
      <h3 *ngIf="isEnvironmentDevelopment && !baseUrl.includes('yamama')">
        مرحبا بك فى نظام العرض التجريبي
      </h3>
      <!-- <div *ngIf="isEnvironmentDevelopment && baseUrl.includes('yamama')">
        <img class="img-fluid for-light" src="assets/images/logo/ymamaLogo.jpeg" alt="looginpage" style="height: 170px; width: 200px;" />
        <img class="img-fluid for-dark" src="assets/images/logo/ymamaLogo.jpeg" alt="looginpage" style="height: 170px; width: 200px;"/>
       </div> -->
      <div *ngIf="isEnvironmentDevelopment && baseUrl.includes('yamama')">
        <img
          class="img-fluid for-light"
          src="assets/images/logo/ymamaLogo.jpeg"
          alt="looginpage"
          style="height: 170px; width: 200px"
        />
        <img
          class="img-fluid for-dark"
          src="assets/images/logo/ymamaLogo.jpeg"
          alt="looginpage"
          style="height: 170px; width: 200px"
        />
      </div>
      <h3
        *ngIf="!isEnvironmentDevelopment && !baseUrl.includes('ajial')"
        class="flex flex-col ai-c gap-1"
      >
        <div class="img companyImg">
          <img src="assets/images/logo/noblaa.png" alt="" />
        </div>
        <!-- <span>مرحباً بك في مدارس النبلاء الأهلية</span> -->
      </h3>
      <div *ngIf="!isEnvironmentDevelopment && baseUrl.includes('ajial')">
        <img
          class="img-fluid for-light"
          src="assets/images/logo/ymamaLogo.jpeg"
          alt="looginpage"
          style="height: 170px; width: 200px"
        />
        <img
          class="img-fluid for-dark"
          src="assets/images/logo/ymamaLogo.jpeg"
          alt="looginpage"
          style="height: 170px; width: 200px"
        />
      </div>
      <div class="logo-box">
        <img
          class="img-fluid for-light"
          src="assets/images/logo/report_logo.svg"
          alt="looginpage"
        />
        <img
          class="img-fluid for-dark"
          src="assets/images/logo/report_logo.svg"
          alt="looginpage"
        />
      </div>
    </div>
    <div class="login-boxes">
      <a [href]="studentUrl">
        <div class="box">
          <div class="img">
            <img src="assets/images/Raising_hand.png" alt="" />
          </div>
          <h4>طالب</h4>
        </div>
      </a>
      <a [href]="parentUrL">
        <div class="box">
          <div class="img">
            <img src="assets/images/Parents.png" alt="" />
          </div>
          <h4>ولي الأمر</h4>
        </div>
      </a>
      <a [href]="teacherUrl">
        <div class="box">
          <div class="img">
            <img src="assets/images/Teachers_Day.png" alt="" />
          </div>
          <h4>معلم</h4>
        </div>
      </a>
      <a [routerLink]="'/auth/login'">
        <div class="box">
          <div class="img">
            <img src="assets/images/Businessman.png" alt="" />
          </div>
          <h4>الموظف</h4>
        </div>
      </a>
      <a [href]="dmUrl">
        <div class="box">
          <div class="img">
            <img src="assets/images/New_idea_bro.png" alt="" />
          </div>
          <h4>المستثمرين</h4>
        </div>
      </a>
      <a [routerLink]="'/auth/login'">
        <div class="box">
          <div class="img">
            <img src="assets/images/CEO.png" alt="" />
          </div>
          <h4>رئيس الشركة</h4>
        </div>
      </a>
    </div>
  </div>
</div>
