import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-which-user-login',
  templateUrl: './which-user-login.component.html',
  styleUrls: ['./which-user-login.component.scss']
})
export class WhichUserLoginComponent {

  baseUrl = environment.basicUrl
  parentUrL = environment.parentUrl
  teacherUrl = environment.teacherUrl
  studentUrl = environment.studentUrl
  dmUrl = environment.dmUrl
  isEnvironmentDevelopment: boolean = environment.development ? true : false
}
